<template>
  <div><Header></Header><Calculator /></div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/HeaderComponent.vue";
import Calculator from "@/components/CalculatorComponent.vue";

export default {
  name: "HomeView",
  components: {
    Header,
    Calculator,
  },
};
</script>
<style scoped></style>
