<template>
  <div>
    <hr />
    <div class="container-fluid">
      <p>
        Just
        <a
          href="https://iris.fbr.gov.pk/#verifications"
          class="font-weight-bold"
          target="_blank"
          >click this link</a
        >
        to confirm Tax Payer Status directly from FBR Records.
      </p>
      <label class="fw-bold">Select TaxPayer Status for Income Tax</label>
      <select
        class="form-control InputFields"
        id="statusDropdown"
        v-model="selectedStatus"
      >
        <option value="">Select</option>
        <option value="Filer">Filer</option>
        <option value="Non-Filer">Non-Filer</option>
      </select>
      <label class="fw-bold">Selection of Province</label>
      <div class="ProvinceContainer">
        <select
          class="form-control InputFields"
          id="statusDropdown"
          v-model="selectedProvince"
        >
          <option value="">Select</option>
          <option value="SRB">SRB</option>
          <option value="PRA">PRA</option>
          <option value="BST">BST</option>
          <option value="KPRA">KPRA</option>
        </select>

        <div v-if="selectedProvince === 'SRB'" class="linkAdjust">
          <a
            class="fw-semibold"
            href="https://e.srb.gos.pk/Registration/ATL_Status.aspx"
            target="_blank"
            >Click for ATL Status</a
          >
        </div>
        <div v-if="selectedProvince === 'PRA'" class="linkAdjust">
          <a
            class="fw-semibold"
            href="https://e.pra.punjab.gov.pk/ATLSearchUtility.aspx"
            target="_blank"
            >Click for ATL Status</a
          >
        </div>
        <div v-if="selectedProvince === 'BST'" class="linkAdjust">
          <a
            class="fw-semibold"
            href="https://bra.gob.pk/ATLSearchUtility.aspx"
            target="_blank"
            >Click for ATL Status</a
          >
        </div>
        <div v-if="selectedProvince === 'KPRA'" class="linkAdjust">
          <a
            class="fw-semibold"
            href="https://kpra.kp.gov.pk/ATLSearchUtility.aspx"
            target="_blank"
            >Click for ATL Status</a
          >
        </div>
      </div>

      <label class="fw-bold">Select Relevant Sale Tax Rate</label>

      <select class="form-control InputFields" v-model="selectedTaxRate">
        <option value="">Select</option>
        <option v-for="rate in taxRates" :key="rate" :value="rate">
          {{ rate }}
        </option>
      </select>
      <div v-if="selectedProvince && selectedTaxRate">
        <label class="fw-bold">Select Relevant Category of Service</label>

        <div
          v-for="category in serviceCategories"
          :key="category"
          class="form-check"
        >
          <input
            type="radio"
            :id="category"
            :value="category"
            v-model="selectedCategory"
            class="form-check-input"
          />
          <label :for="category" class="form-check-label">
            {{ category }}
          </label>
        </div>
      </div>
      <label class="fw-bold">Select Type of Taxpayer</label>
      <select class="form-control InputFields" v-model="selectedTaxpayerType">
        <option value="">Select</option>
        <option value="Company">Company</option>
        <option value="Others than Company">Others than Company</option>
        <option value="Execution of Contract">Execution of Contract</option>
        <option value="Sports Person">Sports Person</option>
        <option value="Specified Services">Specified Services *</option>
        <option value="Services of Electronic & Print Media">
          Services of Electronic & Print Media
        </option>
        <option value="Oil Tanker Contractor Services">
          Oil Tanker Contractor Services
        </option>
        <option value="Commission Advertising Agents">
          Commission Advertising Agents
        </option>
        <option value="Commission Life Insurance Agent">
          Commission Life Insurance Agent where Income is less than 0.5M per
          anum
        </option>
        <option value="Brokerage & Commission in Any Other Case">
          Brokerage & Commission in Any Other Case
        </option>
        <option value="Prize on crossword puzzle">
          Prize on crossword puzzle
        </option>
        <option value="Lottery, Prize, Quiz winning">
          Lottery, Prize, Quiz winning and Prize offered by company for sales
          promotion
        </option>
        <option value="Royalty and Technical Services">
          Royalty and Technical Services
        </option>
        <option value="Payment to Non-resident">
          Payment to Non-resident against a construction contract or services
          rendered relating thereto; or a contract for advertisement services
          rendered by T.V. Satellite Channels
        </option>
        <option value="Payment of insurance premium">
          Payment of insurance premium or reinsurance premium to a non-resident
          person
        </option>
        <option value="Payment for advertisement services">
          Payment for advertisement services to a non-resident media person
          relaying from outside Pakistan
        </option>
        <option value="Payment for foreign produced commercial">
          Payment for foreign produced commercial for advertisement on any
          television channel or any other media
        </option>
        <option value="Payment to Non-resident against fee">
          Payment to Non-resident against fee for offshore digital services
        </option>
        <option value="Profit on Debt to Non-resident">
          Profit on Debt to Non-resident
        </option>
        <option value="Other Payments not elsewhere specified">
          Other Payments not elsewhere specified
        </option>
        <option value="Cohesive Business Operations">
          Cohesive Business Operations under section 2(41)(g) Subject to
          Exemption Certificate
        </option>
      </select>

      <!-- New Section: List of Specified Services -->
      <div v-if="selectedTaxpayerType === 'Specified Services'">
        <label class="fw-bold">List of Specified Services</label>
        <select
          class="form-control InputFields"
          v-model="selectedSpecifiedService"
        >
          <option value="">Select</option>
          <option value="Transport Services">Transport Services</option>
          <option value="Freight Forwarding Services">
            Freight Forwarding Services
          </option>
          <option value="Air Cargo Services">Air Cargo Services</option>
          <option value="Courier Services">Courier Services</option>
          <option value="Manpower Outsourcing Services">
            Manpower Outsourcing Services
          </option>
          <option value="Hotel Services">Hotel Services</option>
          <option value="Security Guard Services">
            Security Guard Services
          </option>
          <option value="Software Development Services">
            Software Development Services
          </option>
          <option value="IT Services & IT enabled Services">
            IT Services & IT enabled Services as defined in section 2
          </option>
          <option value="Tracking Services">Tracking Services</option>
          <option value="Advertising Services">
            Advertising Services (other than by print or electronic media)
          </option>
          <option value="Share Registrar Services">
            Share Registrar Services
          </option>
          <option value="Engineering Services">
            Engineering Services including Architectural Services
          </option>
          <option value="Warehousing Services">Warehousing Services</option>
          <option value="Asset Management Services">
            Services rendered by Asset Management Companies
          </option>
          <option value="Data Services">
            Data Services provided under license issued by Pakistan
            Telecommunication Authority
          </option>
          <option value="Telecommunication infrastructure services">
            Telecommunication infrastructure (tower) services, car rental
            services
          </option>
          <option value="Car rental services">Car rental services</option>
          <option value="Building Maintenance Services">
            Building Maintenance Services
          </option>
          <option value="Stock Exchange Services">
            Services rendered by Pakistan Stock Exchange Limited
          </option>
          <option value="Mercantile Exchange Services">
            Services rendered by Pakistan Stock Mercantile Exchange Limited
          </option>
          <option value="Inspection & Certification Services">
            Inspection Services & Certification Services
          </option>
          <option value="Testing & Training Services">
            Testing & Training Services
          </option>
          <option value="Oilfield Services">Oilfield Services</option>
          <option value="Telecommunication Services">
            Telecommunication Services
          </option>
          <option value="Collateral Management Services">
            Collateral Management Services
          </option>
          <option value="Travel and Tour Services">
            Travel and Tour Services
          </option>
          <option value="REIT Management Services">
            REIT Management Services
          </option>
          <option value="NCCPL Services">
            Services Rendered by National Clearing Company of Pakistan Limited
            (NCCPL)
          </option>
        </select>
      </div>
      <p class="fw-bold">
        Income Tax Withholding Rate:
        <span class="bg-warning p-1"> {{ formattedIncomeTaxRate }} </span>
      </p>

      <p class="fw-bold">
        Sales Tax Withholding Rate:
        <span class="bg-warning p-1">
          {{ formattedSalesTaxWithholdingRate }}
        </span>
      </p>
      <div v-if="selectedTaxpayerType != '' && selectedCategory != ''">
        <label class="fw-bold">Invoice Amount Exclusive of Sales Tax</label>
        <input
          type="text"
          class="form-control InputFields"
          :value="formattedInvoiceAmountExclusive"
          @input="updateInvoiceAmountExclusive"
          placeholder="Enter Amount"
        />

        <label class="fw-bold">Sales Tax</label>
        <input
          type="text"
          class="form-control InputFields"
          :value="formatNumber(salesTax)"
          disabled
        />

        <label class="fw-bold">Invoice Amount Inclusive of Sales Tax</label>
        <input
          type="text"
          class="form-control InputFields"
          :value="formatNumber(invoiceAmountInclusive)"
          disabled
        />

        <label class="fw-bold">Income Tax Withholding</label>
        <input
          type="text"
          class="form-control InputFields"
          :value="formatNumber(incomeTaxWithholding)"
          disabled
        />

        <label class="fw-bold">Sales Tax Withholding</label>
        <input
          type="text"
          class="form-control InputFields"
          :value="formatNumber(salesTaxWithholding)"
          disabled
        />

        <label class="fw-bold">Payable to Vendor</label>
        <input
          type="text"
          class="form-control InputFields"
          :value="formatNumber(payableToVendor)"
          disabled
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Calculator", // Corrected the name

  data() {
    return {
      selectedStatus: "",
      selectedProvince: "",
      selectedTaxRate: "",
      selectedCategory: "",
      selectedTaxpayerType: "",
      selectedSpecifiedService: "",
      invoiceAmountExclusive: 0,

      taxRatesByType: {
        Company: { filer: 9, nonFiler: 18 },
        "Others than Company": { filer: 11, nonFiler: 22 },
        "Execution of Contract": { filer: 8, nonFiler: 16 },
        "Sports Person": { filer: 10, nonFiler: 20 },
        "Specified Services": { filer: 4, nonFiler: 8 },
        "Services of Electronic & Print Media": { filer: 1.5, nonFiler: 3 },
        "Oil Tanker Contractor Services": { filer: 2, nonFiler: 4 },
        "Commission Advertising Agents": { filer: 10, nonFiler: 20 },
        "Commission Life Insurance Agent": { filer: 8, nonFiler: 16 },
        "Brokerage & Commission in Any Other Case": { filer: 12, nonFiler: 24 },
        "Prize on crossword puzzle": { filer: 15, nonFiler: 30 },
        "Lottery, Prize, Quiz winning": { filer: 20, nonFiler: 40 },
        "Royalty and Technical Services": { filer: 15, nonFiler: 15 },
        "Payment to Non-resident": { filer: 7, nonFiler: 7 },
        "Payment of insurance premium": { filer: 5, nonFiler: 5 },
        "Payment for advertisement services": { filer: 10, nonFiler: 10 },
        "Payment for foreign produced commercial": { filer: 20, nonFiler: 20 },
        "Payment to Non-resident against fee": { filer: 10, nonFiler: 10 },
        "Profit on Debt to Non-resident": { filer: 10, nonFiler: 10 },
        "Other Payments not elsewhere specified": { filer: 20, nonFiler: 20 },
        "Cohesive Business Operations": { filer: 1.4, nonFiler: 1.4 },
      },
      withholdingRates: {
        "SRB Registered Person": 20,
        "SRB Unregistered Person": (15 / 115) * 100,
        "SRB Telecommunication, Financial Institutions, Insurance, Port, Airport, Terminal Operators, and Airport Ground Services": 0,
        "SRB Advertisement, Rent, Auctioneers, Transporters": 100,
        "BST Registered Person": 20,
        "BST Unregistered Person": (15 / 115) * 100,
        "BST Telecommunication, banking company, insurance company (other than a re-insurance company), financial institution, port operator, airport operator, terminal operator, and airport ground services.": 0,
        "KPRA Registered Person": 50,
        "KPRA Unregistered Person": 15,
        "KPRA Advertising, Services subject to Reduced Rate": 100,
        "KPRA Telecommunication Services": 0,
        "PRA Registered Companies, Telecommunication Services, Banks, Courier, and Insurance Services": 0,
        "PRA Registered Person other than Companies": 100,
        "PRA Unregistered Person": 16,
      },
    };
  },
  methods: {
    formatNumber(value) {
      if (value === null || value === undefined || isNaN(value)) {
        return "";
      }
      return parseFloat(value).toLocaleString("en-US");
    },
    parseNumber(value) {
      return parseFloat(value.replace(/,/g, "")) || 0;
    },
    updateInvoiceAmountExclusive(event) {
      const formattedValue = event.target.value;
      this.invoiceAmountExclusive = this.parseNumber(formattedValue);
    },
  },

  computed: {
    formattedInvoiceAmountExclusive() {
      return this.formatNumber(this.invoiceAmountExclusive);
    },
    taxRates() {
      switch (this.selectedProvince) {
        case "SRB":
          return [
            "Unregistered",
            "Exempt",
            "19.5%",
            "15%",
            "10%",
            "8%",
            "5%",
            "3%",
            "2%",
          ];
        case "PRA":
          return ["Unregistered", "Exempt", "19.50%", "16%"];
        case "BST":
          return ["Unregistered", "Exempt", "19.50%", "15%"];
        case "KPRA":
          return [
            "Unregistered",
            "Exempt",
            "19.50%",
            "15%",
            "10%",
            "8%",
            "5%",
            "2%",
          ];
        default:
          return [];
      }
    },
    serviceCategories() {
      if (this.selectedTaxRate === "Unregistered") {
        switch (this.selectedProvince) {
          case "SRB":
            return ["SRB Unregistered Person"];
          case "PRA":
            return ["PRA Unregistered Person"];
          case "BST":
            return ["BST Unregistered Person"];
          case "KPRA":
            return ["KPRA Unregistered Person"];
          default:
            return [];
        }
      } else {
        switch (this.selectedProvince) {
          case "SRB":
            return [
              "SRB Registered Person",
              "SRB Telecommunication, Financial Institutions, Insurance, Port, Airport, Terminal Operators, and Airport Ground Services",
              "SRB Advertisement, Rent, Auctioneers, Transporters",
            ];
          case "PRA":
            return [
              "PRA Registered Companies, Telecommunication Services, Banks, Courier, and Insurance Services",
              "PRA Registered Person other than Companies",
            ];
          case "BST":
            return [
              "BST Registered Person",
              "BST Telecommunication, banking company, insurance company (other than a re-insurance company), financial institution, port operator, airport operator, terminal operator, and airport ground services.",
            ];
          case "KPRA":
            return [
              "KPRA Registered Person",
              "KPRA Advertising, Services subject to Reduced Rate",
              "KPRA Telecommunication Services",
            ];
          default:
            return [];
        }
      }
    },
    salesTax() {
      if (
        this.selectedTaxRate === "Unregistered" ||
        this.selectedTaxRate === "Exempt"
      ) {
        return 0;
      }

      const rate = parseFloat(this.selectedTaxRate) / 100;
      return this.invoiceAmountExclusive * rate || 0;
    },
    invoiceAmountInclusive() {
      return this.invoiceAmountExclusive + this.salesTax;
    },

    incomeTaxRate() {
      if (!this.selectedTaxpayerType || !this.selectedStatus) {
        return 0;
      }
      const statusKey = this.selectedStatus === "Filer" ? "filer" : "nonFiler";
      return this.taxRatesByType[this.selectedTaxpayerType]?.[statusKey] || 0;
    },

    salesTaxWithholdingRate() {
      if (!this.selectedCategory) {
        return 0;
      }
      return this.withholdingRates[this.selectedCategory] || 0;
    },

    formattedIncomeTaxRate() {
      return `${this.incomeTaxRate.toFixed(2)}%`;
    },

    formattedSalesTaxWithholdingRate() {
      return `${this.salesTaxWithholdingRate.toFixed(2)}%`;
    },

    salesTaxWithholding() {
      if (this.salesTax === 0) {
        return (
          (this.invoiceAmountInclusive * this.salesTaxWithholdingRate) /
          100
        ).toFixed(2);
      }
      return ((this.salesTax * this.salesTaxWithholdingRate) / 100).toFixed(2);
    },
    incomeTaxWithholding() {
      return (this.invoiceAmountInclusive * this.incomeTaxRate) / 100;
    },

    payableToVendor() {
      return (
        this.invoiceAmountExclusive +
        this.salesTax -
        this.incomeTaxWithholding -
        this.salesTaxWithholding
      ).toFixed(2);
    },
  },
};
</script>

<style scoped>
.container-fluid {
  margin-bottom: 20px;
}
.container-fluid label {
  margin-bottom: 10px;
}
.InputFields {
  width: 50vw;
  margin-bottom: 10px;
}
.ProvinceContainer {
  display: flex;
}
.linkAdjust {
  margin-top: 1rem;
  margin-left: 1rem;
}
.radioButtons {
  width: 50vw;
}
.SalesTaxPurpose {
  width: 30vw;
}
@media (max-width: 992px) {
  .InputFields {
    width: 75vw;
  }
  .radioButtons {
    width: 75vw;
  }
  .SalesTaxPurpose {
    width: 75vw;
  }
}
@media (max-width: 767px) {
  .ProvinceContainer {
    flex-direction: column;
  }
  .InputFields {
    width: 90vw;
  }
  .linkAdjust {
    margin-top: 0;
    margin-left: 0;
  }
  .radioButtons {
    width: 90vw;
  }
  .SalesTaxPurpose {
    width: 90vw;
  }
}
.VendorStatusRadio {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
